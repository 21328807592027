import React from "react";

import {
  CategoryToAncestorsCategoryConnection,
  RootQueryToPostConnection,
} from "interfaces/wordpress";
import Slider from "components/the-buzz-theme/slider/slider";

const lexemes = {
  titleScreenReader: "Articles by Category",
  titleListScreenReader: "Articles",
  ctaTextScreenReader: "View more",
  ctaTextExtraScreenReader: " articles",
};

export type Props = {
  categoryTabs?: CategoryToAncestorsCategoryConnection;
};

export default function CategoriesAccordion(props: Props) {
  return (
    <>
      {/** Accordion-container - Mobile */}
      <div className="accordion-container">
        <h2 id="cat-heading" className="screen-reader-text">
          {lexemes.titleScreenReader}
        </h2>

        <ul
          id="catAccordion"
          className="accordion accordion-cards"
          aria-labelledby="cat-heading"
        >
          {props.categoryTabs?.edges?.map((edge, key) => {
            const id = key + 1;
            const node = edge?.node;
            return (
              <li
                className="nav-item accordion-item"
                key={`accordion-item-${key}`}
              >
                <button
                  className={`nav-link accordion-button collapsed ${key === 0 ? "active" : ""}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#cat-${id}-accordion`}
                  aria-expanded="false"
                  aria-controls={`cat-${id}-accordion`}
                  style={{
                    backgroundColor:
                      node.categoryPageFields?.categoryColor || undefined,
                  }}
                >
                  <h3 className="tab-title" id={`${id}-accordion`}>
                    {node?.name}
                    <span className="screen-reader-text">
                      {lexemes.titleListScreenReader}
                    </span>
                  </h3>
                </button>
                <div
                  id={`cat-${id}-accordion`}
                  className="tab-content accordion-collapse collapse site-loop"
                  aria-labelledby={`${id}-accordion`}
                  data-bs-parent="#catAccordion"
                >
                  <div
                    className="accordion-body"
                    style={{
                      backgroundColor:
                        node.categoryPageFields?.categoryColor || undefined,
                    }}
                  >
                    <Slider
                      stickyPosts={
                        (node.posts as RootQueryToPostConnection) || undefined
                      }
                      isMobileOnly={true}
                    />
                    <div className="category-link">
                      <a
                        className="view-more-btn more-btn btn btn-outline-primary"
                        role="button"
                        href={`/the-buzz/${node.slug}`}
                      >
                        {lexemes.ctaTextScreenReader}
                        <span className="screen-reader-text">{node.name}</span>
                        {lexemes.ctaTextExtraScreenReader}
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
