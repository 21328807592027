import React from "react";

import { CategoryToAncestorsCategoryConnection } from "interfaces/wordpress";
import CategoriesAccordion from "./categories-accordion";
import CategoriesTabs from "./categories-tabs";

export type Props = {
  categoryTabs?: CategoryToAncestorsCategoryConnection;
};

export default function Categories(props: Props) {
  return (
    <section id="newest-posts" className="post-loop">
      <div className="container-both">
        <CategoriesAccordion categoryTabs={props.categoryTabs} />
        <CategoriesTabs categoryTabs={props.categoryTabs} />
      </div>
    </section>
  );
}
