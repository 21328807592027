import React from "react";

import { ResolvedType } from "interfaces";
import { getRecentPosts } from "lib/api";
import Card from "components/the-buzz-theme/card/card";

const lexemes = {
  ctaText: "View More Articles",
  pagerTitleStart: "Now viewing ",
  pagerTitleStartNext: " Page ",
  pagerTitleStartMiddle: " of ",
  pagerTitleStartEnd: " total pages.",
};

export type Props = {
  id?: string;
  title?: string;
  posts?: ResolvedType<typeof getRecentPosts>;
  sectionClassname?: string;
  total?: number;
  categoryName?: string;
  tag?: string;
};

export default function PostFeed({
  title,
  posts,
  sectionClassname,
  total,
  categoryName,
  tag,
  id = "all-posts",
}: Props) {
  const [hasMoreArticles, setHasMoreArticles] = React.useState(
    posts?.hasNextPage,
  );
  const [endCursor, setEndCursor] = React.useState(posts?.endCursor);
  const [pager, setPager] = React.useState(1);
  const [recentPosts] = React.useState([posts?.posts]);
  const [isLoading, setIsLoading] = React.useState(false);

  const loadMoreArticles = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setIsLoading(true);

      if (!endCursor) {
        setIsLoading(false);
        return;
      } else {
        setPager((value) => value + 1);
      }

      getMoreRecentPosts(endCursor, categoryName, tag).then(
        (moreRecentPosts) => {
          const loadedPosts = moreRecentPosts.posts.map((item) => {
            return item;
          });

          setIsLoading(false);
          setHasMoreArticles(moreRecentPosts.hasNextPage);
          setEndCursor(moreRecentPosts.endCursor);
          recentPosts.push(loadedPosts);
        },
      );
    },
    [endCursor, recentPosts, categoryName, tag],
  );

  return (
    <section className={sectionClassname} id={id}>
      {title ? (
        <div className="container-xxl">
          <h2 className="section-title">{title}</h2>
        </div>
      ) : null}

      <div
        id="ajax-load-more"
        className="ajax-load-more-wrap white alm-0 alm-is-loaded"
        data-alm-id="0"
        data-canonical-url="https://bumble.com/the-buzz"
        data-slug="home"
        data-post-id="0"
        data-localized="ajax_load_more_vars"
        data-alm-object="ajax_load_more"
        data-total-posts="878"
      >
        <div
          aria-live="polite"
          aria-atomic="true"
          className="alm-listing alm-ajax container-xxl all-post-loop site-loop"
          data-container-type="div"
          data-loading-style="white"
          data-repeater="default"
          data-post-type="post"
          // ?
          data-post-not-in="15751 16610"
          data-order="DESC"
          data-orderby="date"
          data-offset="0"
          data-posts-per-page="12"
          data-scroll="false"
          data-button-label={lexemes.ctaText}
          data-button-done-label="You have reached the end of article list."
        >
          {recentPosts.map((recentPostList, recentPostListKey) => (
            <div
              className="alm-reveal row post-loop-container"
              key={recentPostListKey}
            >
              {recentPostList &&
                recentPostList.map((recentPostListitem, postKey) => (
                  <Card post={recentPostListitem} key={postKey} />
                ))}
            </div>
          ))}
        </div>
        {total !== 1 ? (
          <div
            className="alm-results-text"
            aria-live="polite"
            aria-atomic="true"
          >
            <span className="screen-reader-text">
              {lexemes.pagerTitleStart}
            </span>
            {lexemes.pagerTitleStartNext}
            <span className="alm-results-page"> {pager} </span>
            {lexemes.pagerTitleStartMiddle}
            <span className="alm-results-pages"> {total || 1}</span>
            <span className="screen-reader-text">
              {lexemes.pagerTitleStartEnd}
            </span>
          </div>
        ) : null}
        {hasMoreArticles ? (
          <div
            className="alm-btn-wrap"
            data-rel="ajax-load-more"
            style={{ visibility: "visible" }}
          >
            <button
              className="alm-load-more-btn more btn btn-outline-primary"
              type="button"
              onClick={loadMoreArticles}
              disabled={isLoading}
            >
              {lexemes.ctaText}
            </button>
          </div>
        ) : null}
      </div>
    </section>
  );
}

function getMoreRecentPosts(
  after: string,
  categoryName?: string,
  tag?: string,
): ReturnType<typeof getRecentPosts> {
  let apiUrl = `/the-buzz/api/recent-posts?after=${after}`;

  if (categoryName) {
    apiUrl = `${apiUrl}&categoryName=${categoryName}`;
  }
  if (tag) {
    apiUrl = `${apiUrl}&tag=${tag}`;
  }
  return fetch(apiUrl).then((blob) => blob.json());
}
