import React from "react";

import { Post } from "interfaces/wordpress";
import Card from "components/the-buzz-theme/card/card";

export type Props = {
  stickyPost?: Post;
};

export default function SliderSlide({ stickyPost }: Props) {
  return (
    <>
      {stickyPost ? (
        <div className="swiper-slide">
          {
            <Card
              classnames="col slide-single"
              post={stickyPost || undefined}
            />
          }
        </div>
      ) : null}
    </>
  );
}
