import React from "react";

import { Post, RootQueryToPostConnection } from "interfaces/wordpress";
import SliderSlide from "components/the-buzz-theme/slider/slider-slide";
import { sliderOptions } from "./slider-options";

export type Props = {
  stickyPosts?: RootQueryToPostConnection;
  isMobileOnly?: boolean;
};

export default function Slider(props: Props) {
  React.useEffect(() => {
    // @ts-ignore
    const Swiper = global.window.Swiper;
    /* eslint-disable no-new */
    const desktopSwiperList =
      !props.isMobileOnly && Swiper
        ? new Swiper(".swiper-desktop-container", sliderOptions.desktop)
        : undefined;

    /* Cards - Mobile */
    /* eslint-disable no-new */
    const mobileSwiperList =
      Swiper && new Swiper(".swiper-mobile-container", sliderOptions.mobile);

    return () => {
      if (mobileSwiperList) {
        mobileSwiperList.map((mobileSwiper: any) => mobileSwiper.destroy());
      }
      if (desktopSwiperList) {
        desktopSwiperList.map((desktopSwiper: any) => desktopSwiper.destroy());
      }
    };
  }, [props.isMobileOnly]);

  const swiperMobile = (
    <div className="swiper-mobile">
      <div className="swiper-mobile-container post-loop-container">
        <div className="swiper-wrapper">
          {props.stickyPosts?.nodes &&
            props.stickyPosts?.nodes.map((stickyPost: Post, index) => (
              <React.Fragment key={stickyPost?.id || `slider-slider-${index}`}>
                <SliderSlide stickyPost={stickyPost || undefined} />
              </React.Fragment>
            ))}
        </div>
        {/* .swiper-wrapper  */}
      </div>
      {/* .swiper-mobile-container  */}
    </div>
  );
  if (props.isMobileOnly) {
    return swiperMobile;
  }
  const swiperDesktop = (
    <div className="swiper swiper-desktop">
      <div className="swiper-desktop-container swiper-container row post-loop-container">
        <div className="swiper-wrapper">
          {/* // Featured Post Loop  */}
          {props.stickyPosts?.nodes &&
            props.stickyPosts?.nodes.map((stickyPost: Post, index) => (
              <React.Fragment key={stickyPost?.id || `slider-slider-${index}`}>
                <SliderSlide stickyPost={stickyPost || undefined} />
              </React.Fragment>
            ))}
        </div>
        {/* .swiper-wrapper  */}
      </div>
      {/* .swiper-desktop-container row  */}
    </div>
  );
  const swiperWrapper = (
    <div id="featured-post" className="post-horizontal">
      <h2 className="screen-reader-text">Featured Articles for this Site</h2>
      <div className="container-xxl site-loop">
        {swiperMobile}

        {props.isMobileOnly ? null : swiperDesktop}

        <button type="button" className="swiper-button-next">
          <span className="screen-reader-text">Go to Next Slide</span>
        </button>
        <button type="button" className="swiper-button-prev">
          <span className="screen-reader-text">Go to Previous Slide</span>
        </button>
        <div className="swiper-pagination"></div>
      </div>
    </div>
  );
  return swiperWrapper;
}
