import React from "react";

import TopHeader from "components/the-buzz-theme/header/top-header";
import { Header } from "components/the-buzz-theme/header/header";
import { Footer } from "components/the-buzz-theme/footer/footer";
import { SVGList } from "components/the-buzz-theme/svg-list/svg-list";
import { Main } from "components/the-buzz-theme/main/main";

import { ResolvedType } from "interfaces";
import {
  RootQueryToPostConnection,
  CategoryToAncestorsCategoryConnection,
} from "interfaces/wordpress";
import { getRecentPosts } from "lib/api";
import Slider from "components/the-buzz-theme/slider/slider";
import Categories from "components/the-buzz-theme/categories/categories";
import PostFeed from "components/the-buzz-theme/post-feed/post-feed";

export type Props = {
  stickyPosts?: RootQueryToPostConnection;
  categoryTabs?: CategoryToAncestorsCategoryConnection;
  recentPosts?: ResolvedType<typeof getRecentPosts>;
  recentPostsTotal?: number;
};

export default function MainPage(props: Props) {
  return (
    <>
      <SVGList />
      <TopHeader />
      <Header />
      <Main>
        <Slider stickyPosts={props.stickyPosts} />
        <Categories categoryTabs={props.categoryTabs} />
        <PostFeed
          title="All Posts"
          posts={props.recentPosts}
          total={props.recentPostsTotal}
        />
      </Main>
      <Footer />
    </>
  );
}
