import React from "react";

import {
  CategoryToAncestorsCategoryConnection,
  Post,
} from "interfaces/wordpress";
import Card from "components/the-buzz-theme/card/card";

const lexemes = {
  titleScreenReader: "Articles by Category",
  titleListScreenReader: " Articles",
  ctaTextScreenReader: "View more",
  ctaTextExtraScreenReader: " articles",
};

export type Props = {
  categoryTabs?: CategoryToAncestorsCategoryConnection;
};

export default function categoryTabs(props: Props) {
  const categoryTabsNavigation = (
    <ul
      id="catTabs"
      className="nav nav-tabs"
      role="tablist"
      aria-labelledby="cat-heading"
    >
      {props.categoryTabs?.edges?.map((edge, navKey) => {
        const id = navKey + 1;
        const node = edge?.node;

        return (
          <li className="nav-item tab-item" key={`nav-item-${navKey}`}>
            <button
              className={`nav-link  ${navKey === 0 ? "active" : ""}`}
              id={`cat${id}-tab`}
              data-bs-toggle="tab"
              data-bs-target={`#cat-${id}`}
              type="button"
              role="tab"
              aria-selected={navKey === 0 ? "true" : "false"}
              aria-controls={`cat${id}`}
              style={{
                backgroundColor:
                  node.categoryPageFields?.categoryColor || undefined,
              }}
            >
              <h3 className="tab-title" id={`${id}-tab`}>
                {node?.name}
                <span className="screen-reader-text">
                  {lexemes.titleListScreenReader}
                </span>
              </h3>
            </button>
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className="container-xxl tabs-container">
      {categoryTabsNavigation}
      <div id="nav-tabContent" className="tab-content site-loop">
        {props.categoryTabs?.edges?.map((edge, categoryKey) => {
          const id = categoryKey + 1;
          const node = edge?.node;

          return (
            <div
              id={`cat-${id}`}
              className={`tab-pane fade ${categoryKey === 0 ? "active show" : ""}`}
              role="tabpanel"
              aria-labelledby={`cat${id}-tab`}
              style={{
                backgroundColor:
                  node.categoryPageFields?.categoryColor || undefined,
              }}
              key={`tab-pane-${categoryKey}`}
            >
              <div className="desktop-container post-loop-container">
                <div className="tab-wrapper row row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                  {node.posts?.nodes &&
                    node.posts.nodes
                      .filter((_node, index) => index < 4)
                      // @ts-ignore
                      .map((post: Post, postKey: number) => (
                        <Card post={post || undefined} key={postKey} />
                      ))}
                </div>
              </div>

              <div className="category-link view-more-wrap white">
                <a
                  className="view-more-btn more-btn btn btn-outline-primary"
                  role="button"
                  href={`/the-buzz/${node.slug}`}
                >
                  {lexemes.ctaTextScreenReader}
                  <span className="screen-reader-text">{node?.name}</span>
                  {lexemes.ctaTextExtraScreenReader}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
